import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { FC, useCallback } from "react";
import Spacer from "@src/components/ui/Spacer";
import { useModal } from "@src/hooks/useModal";
import SelectUsersModal from "../../contentLibrary/assignmentModals/SelectUsersModal";
import { UserSelectionInput, UserType } from "@src/types.generated";
import AutomationUserSelectionPreviewButton from "@src/components/contentLibrary/assignmentModals/AutomationUserSelectionPreviewButton";

type Props = {
  userSelectionInput: UserSelectionInput;
  setUserSelectionInput: (input: UserSelectionInput) => void;
};

const PublishModalLibraryAccessPicker: FC<Props> = ({
  userSelectionInput,
  setUserSelectionInput,
}) => {
  const { showModal } = useModal();
  const showSelectUsersModal = useCallback(() => {
    showModal(
      <SelectUsersModal
        input={userSelectionInput}
        onSelect={setUserSelectionInput}
        hideFilters={["people"]}
        hideUserTypes={[UserType.Admin]}
        pillColor="purple"
        title="Library access"
        showUserTypeOptions={true}
      />,
    );
  }, [showModal, userSelectionInput, setUserSelectionInput]);

  return (
    <AutoLayout direction="vertical" flex={1} marginBottom={24}>
      <AutoLayout
        alignmentHorizontal="center"
        marginBottom={12}
        spacingMode="space-between"
        alignSelf="stretch"
        flex={1}
      >
        <AutoLayout direction="vertical">
          <Text type="P1" fontWeight="SemiBold" color={deprecatedTones.black}>
            Library Access
          </Text>
          <Spacer size={4} />
          <Text type="P3" fontWeight="Regular" color={deprecatedTones.gray11}>
            Allow these users to discover this content in their library
          </Text>
        </AutoLayout>
      </AutoLayout>
      <AutomationUserSelectionPreviewButton
        onClick={showSelectUsersModal}
        input={userSelectionInput}
        label="Access rules"
        placeholder="Select users"
      />
    </AutoLayout>
  );
};

export default PublishModalLibraryAccessPicker;

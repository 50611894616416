import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, MediaFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SkillBuilder_SkillVersionQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type SkillBuilder_SkillVersionQuery = { __typename?: 'Query', AdminSkillVersion?: { __typename?: 'SkillVersion', id: string, skill: { __typename?: 'Skill', id: number, lastBuilderActionId?: number | null, libraryItem: { __typename?: 'LibraryItem', id: string, publishState?: Types.PublishState | null, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null }, draftVersion?: { __typename?: 'SkillVersion', id: string } | null, publishedVersion?: { __typename?: 'SkillVersion', id: string } | null }, verificationSteps: Array<{ __typename?: 'VerificationStep', id: string, familyId: string, type: Types.VerificationStepType, photoRequired: Types.NoneOptionalOrRequired, noteRequired: Types.NoneOptionalOrRequired, text?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null }> } | null };

export type SkillBuilder_SkillFragment = { __typename?: 'Skill', id: number, lastBuilderActionId?: number | null, libraryItem: { __typename?: 'LibraryItem', id: string, publishState?: Types.PublishState | null, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null }, draftVersion?: { __typename?: 'SkillVersion', id: string } | null, publishedVersion?: { __typename?: 'SkillVersion', id: string } | null };

export type SkillBuilder_SkillVersionFragment = { __typename?: 'SkillVersion', id: string, skill: { __typename?: 'Skill', id: number, lastBuilderActionId?: number | null, libraryItem: { __typename?: 'LibraryItem', id: string, publishState?: Types.PublishState | null, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null }, draftVersion?: { __typename?: 'SkillVersion', id: string } | null, publishedVersion?: { __typename?: 'SkillVersion', id: string } | null }, verificationSteps: Array<{ __typename?: 'VerificationStep', id: string, familyId: string, type: Types.VerificationStepType, photoRequired: Types.NoneOptionalOrRequired, noteRequired: Types.NoneOptionalOrRequired, text?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null }> };

export type SkillBuilder_VerificationStepFragment = { __typename?: 'VerificationStep', id: string, familyId: string, type: Types.VerificationStepType, photoRequired: Types.NoneOptionalOrRequired, noteRequired: Types.NoneOptionalOrRequired, text?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null };

export const SkillBuilder_SkillFragmentDoc = gql`
    fragment SkillBuilder_Skill on Skill {
  id
  libraryItem {
    id
    name {
      ...TranslationSet
    }
    description {
      ...TranslationSet
    }
    coverImage {
      ...CoverImage
    }
    publishState
  }
  draftVersion {
    id
  }
  publishedVersion {
    id
  }
  lastBuilderActionId
}
    ${TranslationSetFragmentDoc}
${CoverImageFragmentDoc}`;
export const SkillBuilder_VerificationStepFragmentDoc = gql`
    fragment SkillBuilder_VerificationStep on VerificationStep {
  id
  familyId
  type
  text {
    ...TranslationSet
  }
  photoRequired
  noteRequired
}
    ${TranslationSetFragmentDoc}`;
export const SkillBuilder_SkillVersionFragmentDoc = gql`
    fragment SkillBuilder_SkillVersion on SkillVersion {
  id
  skill {
    ...SkillBuilder_Skill
  }
  verificationSteps {
    ...SkillBuilder_VerificationStep
  }
}
    ${SkillBuilder_SkillFragmentDoc}
${SkillBuilder_VerificationStepFragmentDoc}`;
export const SkillBuilder_SkillVersionDocument = gql`
    query SkillBuilder_SkillVersion($id: UUID!) {
  AdminSkillVersion(id: $id) {
    ...SkillBuilder_SkillVersion
  }
}
    ${SkillBuilder_SkillVersionFragmentDoc}`;

/**
 * __useSkillBuilder_SkillVersionQuery__
 *
 * To run a query within a React component, call `useSkillBuilder_SkillVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillBuilder_SkillVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillBuilder_SkillVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillBuilder_SkillVersionQuery(baseOptions: Apollo.QueryHookOptions<SkillBuilder_SkillVersionQuery, SkillBuilder_SkillVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillBuilder_SkillVersionQuery, SkillBuilder_SkillVersionQueryVariables>(SkillBuilder_SkillVersionDocument, options);
      }
export function useSkillBuilder_SkillVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillBuilder_SkillVersionQuery, SkillBuilder_SkillVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillBuilder_SkillVersionQuery, SkillBuilder_SkillVersionQueryVariables>(SkillBuilder_SkillVersionDocument, options);
        }
export type SkillBuilder_SkillVersionQueryHookResult = ReturnType<typeof useSkillBuilder_SkillVersionQuery>;
export type SkillBuilder_SkillVersionLazyQueryHookResult = ReturnType<typeof useSkillBuilder_SkillVersionLazyQuery>;
export type SkillBuilder_SkillVersionQueryResult = Apollo.QueryResult<SkillBuilder_SkillVersionQuery, SkillBuilder_SkillVersionQueryVariables>;
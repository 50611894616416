import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { ContentTagMembershipFragmentDoc, TranslationSetFragmentDoc, CoverImageFragmentDoc } from '../../../../fragments.generated';
import { CourseLibraryItemQrCodeFragmentDoc } from '../../../libraryItemDetailPages/course/CourseDetailPageHeader.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCourseMutationVariables = Types.Exact<{
  courseId: Types.Scalars['Int'];
  input: Types.UpdateCourseInput;
}>;


export type UpdateCourseMutation = { __typename?: 'Mutation', updateCourse: { __typename?: 'CourseMutationResult', success: boolean, course?: { __typename?: 'Course', id: number, enableCertificate: boolean, enablePromptRetries: boolean, passingAccuracyThreshold?: number | null, libraryItem: { __typename?: 'LibraryItem', id: string, dueDate?: string | null, contentTagMemberships: Array<{ __typename?: 'ContentTagMembership', tagId: string, libraryItemId: string }>, qrCode?: { __typename?: 'ContentQRCode', id: string, includeLogo: boolean, includeTitle: boolean, isActive: boolean, imageUrl: string } | null, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } | null, error?: { __typename?: 'Error', code: string, message?: string | null } | null } };


export const UpdateCourseDocument = gql`
    mutation UpdateCourse($courseId: Int!, $input: UpdateCourseInput!) {
  updateCourse(courseId: $courseId, input: $input) {
    course {
      id
      enableCertificate
      enablePromptRetries
      passingAccuracyThreshold
      libraryItem {
        id
        contentTagMemberships {
          ...ContentTagMembership
        }
        qrCode {
          ...CourseLibraryItemQRCode
        }
        name {
          ...TranslationSet
        }
        description {
          ...TranslationSet
        }
        dueDate
      }
    }
    success
    error {
      code
      message
    }
  }
}
    ${ContentTagMembershipFragmentDoc}
${CourseLibraryItemQrCodeFragmentDoc}
${TranslationSetFragmentDoc}`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;